import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { FormattedMessage } from '../../util/reactIntl';

import { Heading, Map } from '../../components';

import css from './ListingPage.module.css';
import { ReactComponent as LocationSVG } from '../../assets/svg/location.svg';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      listingId,
      mapsConfig,
      isLocationTitle,
      customLocationHeader,
    } = this.props;

    if (!geolocation) {
      return null;
    }

    const classes = classNames(rootClassName || css.sectionMap, className);
    const address = publicData && publicData.location ? publicData.location.address : '';
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = mapsConfig.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, mapsConfig.fuzzy.offset, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    return (
      <section className={classes} id="listing-location">
        {customLocationHeader ? (
          <Heading
            as="h2"
            rootClassName={css.sectionHeadingWithExtraMargin}
            onClick={() => {
              if (window) {
                window.open(`https://www.google.com/maps?q=${address}`, '_blank');
              }
            }}
          >
            <span>
              <LocationSVG />
            </span>
            <span>{customLocationHeader}</span>
          </Heading>
        ) : (
          isLocationTitle && (
            <Heading
              as="h2"
              rootClassName={css.sectionHeadingWithExtraMargin}
              onClick={() => {
                if (window) {
                  window.open(`https://www.google.com/maps?q=${address}`, '_blank');
                }
              }}
            >
              <FormattedMessage id="ListingPage.locationTitle" />
            </Heading>
          )
        )}
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </section>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
  isLocationTitle: true,
  customLocationHeader: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
