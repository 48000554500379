import React, { useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage } from '../../util/reactIntl';
import ReviewModal from '../TransactionPage/ReviewModal/ReviewModal';
import { H2, Reviews, FieldTextInput, Form, IconSpinner } from '../../components';

import css from './ListingPage.module.css';
import { FaStar } from 'react-icons/fa6';
import { FaRegStar } from 'react-icons/fa6';
import { FaRegStarHalfStroke } from 'react-icons/fa6';

const starPercentageKeys = [
  { modelKey: 'fiveStarPercentage', value: '5', color: '#3CAEA3' },
  { modelKey: 'fourStarPercentage', value: '4', color: '#3CAEA3' },
  { modelKey: 'threeStarPercentage', value: '3', color: '#F6D55C' },
  { modelKey: 'twoStarPercentage', value: '2', color: '#F69F5C' },
  { modelKey: 'oneStarPercentage', value: '1', color: '#ED553B' },
];

const renderStars = avgRating => {
  const halfStar = avgRating % 1 !== 0;
  const fullStars = Math.floor(avgRating);
  const emptyStars = 5 - Math.ceil(avgRating);

  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(<FaStar key={i} />);
  }

  if (halfStar) {
    stars.push(<FaRegStarHalfStroke key={fullStars} />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<FaRegStar key={fullStars + halfStar + i} />);
  }

  return stars;
};

const StarPercentagesContainer = props => {
  const { reviewsData } = props;

  return (
    <div className={css.circularProgressDiv}>
      {starPercentageKeys.map(star => {
        const percentage = reviewsData[star.modelKey] || 0;
        return (
          <div key={star.modelKey} className={css.starPercentCont}>
            <div
              className={css.percentageOuterDiv}
              style={{
                backgroundImage: `conic-gradient(${star.color} ${percentage *
                  3.6}deg, #ededed 0deg)`,
              }}
            >
              <div>{percentage} %</div>
            </div>
            <span>
              <FaStar
                style={{
                  fill: `${star.color}`,
                }}
              />{' '}
              {parseFloat(star.value).toFixed(1)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const SectionReviews = props => {
  const formRef = useRef(null);
  const [sendReply, setSendReply] = useState('');
  const [writeReviewModal, setWriteReviewModal] = useState(false);

  const {
    reviewsData,
    fetchReviewsError,
    onManageDisableScrolling,
    title,
    listingId,
    sendReviewInProgress,
    sendReviewSuccess,
    sendReviewError,
    onSendReview,
    author,
    sendReplyInProgress,
    sendReplySuccess,
    sendReplyError,
    onSendReply,
    authorDisplayName,
    isAuthenticated,
  } = props;
  const { avgRating = 0, reviewUserCount = 0, reviews = [], totalReviews = 0 } = reviewsData;
  const currentUser = useSelector(state => state.user.currentUser);
  const isProvider = currentUser?.attributes?.profile?.publicData?.customUserType === 'vendor';
  const isAuthor = currentUser?.id?.uuid === author?.id?.uuid;

  const formatAddress = (address = '') => {
    const arr = address.split(', ');
    if (arr.length <= 3) return address;
    const result = arr.slice(-2).join(', ');
    return result.trim();
  };

  return (
    <section className={css.sectionReviews}>
      <div className={css.ratingHead}>
        <div className={css.ratingHead1}>
          <h3>Ratings and reviews ({totalReviews})</h3>
          <div>
            {avgRating ? (
              <div>{renderStars(avgRating)}</div>
            ) : (
              <div>
                <FaRegStar />
                <FaRegStar />
                <FaRegStar />
                <FaRegStar />
                <FaRegStar />
              </div>
            )}
            <p>
              {parseFloat(avgRating).toFixed(1)} <span>/ 5</span>
            </p>
          </div>
          <p>
            Reviewed by <span>{reviewUserCount}</span> users
          </p>
        </div>
        <StarPercentagesContainer reviewsData={reviewsData} />
      </div>
      <div>
        {!isAuthor && isAuthenticated && (
          <button className={css.wrtRevBtn} onClick={e => setWriteReviewModal(true)}>
            Have you tried this product? Share your thoughts and write a review!
          </button>
        )}
      </div>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <div className={css.reviews}>
        {reviews.length > 0
          ? reviews.map((review, idx) => {
              const {
                reviewStars = 0,
                reviewContent,
                userLocation,
                userName,
                postDate,
                replyLocation,
                _id,
                reply,
                replyDate,
              } = review;
              const showReplyBtn =
                !(sendReply === _id) &&
                isAuthor &&
                !sendReplyInProgress &&
                !reply &&
                !(sendReplySuccess === _id);
              return (
                <div className={css.review} key={_id}>
                  <div className={css.reviewStarsDiv}>
                    <div className={css.reviewStars}>{renderStars(reviewStars)}</div>
                    <span>{parseFloat(reviewStars).toFixed(1)}</span>
                  </div>
                  <p className={css.reviewerInfo}>
                    {userName}, <span>{formatAddress(userLocation)}</span>
                  </p>
                  <p className={css.datep}>{moment(postDate).format('Do MMMM, YYYY')}</p>
                  <p className={css.reviewContent}>{reviewContent}</p>
                  {showReplyBtn && (
                    <button onClick={e => setSendReply(_id)} className={css.replyBtn}>
                      Reply
                    </button>
                  )}
                  {sendReplyInProgress === _id ? (
                    <span className={classNames(css.rplyInfoSpn, css.rplyLoad)}>
                      <IconSpinner />
                    </span>
                  ) : sendReplyError?.id === _id ? (
                    <span className={classNames(css.rplyInfoSpn, css.rplyErr)}>
                      Unable to post reply
                    </span>
                  ) : isAuthor && sendReply === _id && !reply && !sendReplySuccess ? (
                    <div className={css.replyDv}>
                      <FinalForm
                        onSubmit={values => {
                          onSendReply(listingId, _id, values.replyContent).then(res => {
                            document.getElementById(_id + 'reply').style.display = 'block';
                            document.getElementById(_id + 'replyContent').innerHTML =
                              values.replyContent;
                          });
                        }}
                        render={renderProps => {
                          const { form, handleSubmit } = renderProps;
                          return (
                            <Form onSubmit={handleSubmit}>
                              <FieldTextInput type="textarea" name="replyContent" />
                              <div className={css.replyBtndv}>
                                <button className={css.sendReply}>Send</button>
                                <button
                                  className={css.rplyClose}
                                  type="button"
                                  onClick={e => {
                                    setSendReply('');
                                    form.restart();
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      />
                    </div>
                  ) : null}
                  <div className={css.replyDv}>
                    <div
                      id={_id + 'reply'}
                      className={classNames(css.reply, { [css.noReply]: !reply })}
                    >
                      <p className={css.reviewerInfo}>
                        {authorDisplayName}, <span>{formatAddress(replyLocation)}</span>
                      </p>
                      <p className={css.datep}>{moment(replyDate).format('Do MMMM, YYYY')}</p>

                      <p id={_id + 'replyContent'}>{reply}</p>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {totalReviews > 5 && (
        <button className={css.moreReviews} type="button">
          View more reviews {' >'}
        </button>
      )}
      <ReviewModal
        id="WriteReviewModal"
        isOpen={writeReviewModal}
        onCloseModal={() => {
          formRef.current.restart();
          setWriteReviewModal(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmitReview={values => {
          onSendReview(listingId, { ...values }).then(res => {
            formRef.current.restart();
            setWriteReviewModal(false);
          });
        }}
        revieweeName={title}
        reviewSent={sendReviewSuccess}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
        // marketplaceName={config.marketplaceName}
        marketplaceName={'Oman Market'}
        formRef={formRef}
      />
    </section>
  );
};

export default SectionReviews;
